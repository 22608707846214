exports.components = {
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-competition-es-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/competition.es.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-competition-es-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-competition-fr-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/competition.fr.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-competition-fr-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-competition-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/competition.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-competition-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-competition-ru-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/competition.ru.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-competition-ru-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-cookies-es-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/cookies.es.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-cookies-es-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-cookies-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/cookies.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-cookies-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-privacy-es-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/privacy.es.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-privacy-es-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-privacy-fr-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/privacy.fr.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-privacy-fr-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-privacy-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/privacy.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-privacy-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-privacy-ru-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/privacy.ru.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-privacy-ru-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-prizes-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/prizes.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-prizes-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-terms-es-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/terms.es.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-terms-es-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-terms-fr-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/terms.fr.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-terms-fr-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-terms-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/terms.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-terms-mdx" */),
  "component---src-components-markdown-template-jsx-content-file-path-src-markdown-terms-ru-mdx": () => import("./../../../src/components/markdown/template.jsx?__contentFilePath=/codebuild/output/src1254865193/src/com.orientalisimo.weekend/src/markdown/terms.ru.mdx" /* webpackChunkName: "component---src-components-markdown-template-jsx-content-file-path-src-markdown-terms-ru-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-fr-js": () => import("./../../../src/pages/contact.fr.js" /* webpackChunkName: "component---src-pages-contact-fr-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contacto-es-js": () => import("./../../../src/pages/contacto.es.js" /* webpackChunkName: "component---src-pages-contacto-es-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-kontakt-ru-js": () => import("./../../../src/pages/kontakt.ru.js" /* webpackChunkName: "component---src-pages-kontakt-ru-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */)
}

